<template>
  <div class="price">
    <div class="container-fluid pl-0">
      <div class="row">
        <div class="col-lg-3 pr-0">
          <h3 class="cashback-sub-title mb-3">Your plan</h3>
          <div class="card-price" :class="{active: activeClass === 'start'}" @click="chooseTariff('start')"  id="one" >
            <div class="price-head d-flex align-items-center mb-4">
              <img class="price-img" src="../../assets/icons/start-setting.svg">
              <h1 class="price-title">Support</h1>
            </div>
            <div class="price-body">
              <ul class="price-menu">
                <li class="price-list"><span>Apple Store</span><img src="../../assets/icons/true.svg"></li>
                <li class="price-list"><span>Google Play</span><img src="../../assets/icons/true.svg"></li>
                <li class="price-list"><span>Server</span><img src="../../assets/icons/true.svg"></li>
                <li class="price-list"><span>Support</span><img src="../../assets/icons/true.svg"></li>
                <li class="price-list"><span>Update App</span><img src="../../assets/icons/true.svg"></li>
                <li class="price-list"><span>New function</span><img src="../../assets/icons/true.svg"></li>
              </ul>
            </div>
            <br><br><br><br>
            <div class="price-foot d-flex align-items-center" style="margin-top: -5px">
              <h1>12$</h1>
              <span>/month</span>
            </div>
          </div>
        </div>
        <div  class="col-lg-3 pr-0">
          <h3 class="cashback-sub-title mb-3">Your balance</h3>
          <div class="card-price" id="two" style="background:#F0F2FF">
            <div class="price-head d-flex align-items-end mb-4">
              <img class="price-img " src="../../assets/icons/pro.svg">
              <h1 class="price-title">App</h1>
            </div>
            <div class="d-flex align-items-end">
              <h1 style="font-size: 22px">{{ getDaysLeft(user_info.activeBefore) }} day(s) left</h1>
            </div>
            <br><br><br>
            <span style="color: #B0B0B0;">Next payment:</span>
            <br>
            <span style="color: #B0B0B0;">{{$moment(user_info.activeBefore).format("DD.MM.YYYY")}}</span>
          </div>
        </div>
      </div>
  
      <h3 class="cashback-sub-title mb-4 mt-4">Select the validity period</h3>
      <div class="row month mb-4">
        <div class="col-3 pr-0"  v-for="price in prices" :key="price.id"  >
          <div class="validity-period">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h3 class="validity-title">{{price.month}} month</h3>
              
              <div class="round-price">
                <img src="../../assets/icons/bird.svg">
              </div>
            </div>
            <div class="valid-desc" style="display: flex;">
              <span class="valid-desc-price">{{price.price}}</span>$/month
              <span class="valid-desc-price" v-if="price.dis" style="margin-left: auto; color:#484848">{{price.dis}}$ disc</span>
            </div>
          </div>
        </div>
      </div>
  
      <h3 class="cashback-sub-title mb-3">Total</h3>
      <div class="total justify-content-between d-flex align-items-center">
          <div>
            <p class="valid-desc">To be paid:</p>
            <h3>{{tobePaid}}$</h3>
          </div>
  
          <div>
            <button class="save">Pay</button>
            </div>
          </div>
  
    </div>
  
  </div>
  </template>
  
  <script>
  import $ from 'jquery';
  
  export default {
  name: "Price",
    data(){
        return{
          prices:[
            {id:1 ,month:6, price:72},
            {id:2 ,month:9, price:100},
            {id:3 ,month:12, price:120, dis: 24},
            {id:4 ,month:24, price:220, dis: 68},
          ],
          activeClass:'start',
          tobePaid:0,
          user_info: ''
        }
    },
  
    methods:{
    chooseTariff(item){
      if(item === 'start'){
          this.prices[0].price = 72;
          this.prices[1].price = 100;

          this.prices[2].price = 120;
          this.prices[2].dis = 24;

          this.prices[3].price = 220;
          this.prices[3].dis = 68;
        this.activeClass = 'start'
      }
      else if(item === 'pro'){
        this.prices.map(price=>{
          return price.price = price.month * 15
        })
        this.activeClass = 'pro'
      }
      else{
        this.prices.map(price=>{
          return price.price = price.month * 299;
        })
        this.activeClass = 'app'
      }
  
    },
    addActiveMonth(){
        
        $(document).ready(function() {
          $('.validity-period').click(function() {
            $('.validity-period.active').removeClass("active");
            $(this).addClass("active");
          });
        });
      },
      getDaysLeft(selectedDate){
        let today = this.$moment();
        let start = this.$moment(selectedDate).startOf('day');
        let end = this.$moment(today).startOf('day');
        return start.diff(end, 'days',true);
      },
      checkAccess1(){
        this.user_info = JSON.parse(localStorage.getItem('user'))
        let days_left = this.getDaysLeft(this.user_info.activeBefore)
        console.log("days_left", days_left)
        if (days_left <= 0) {
          console.log("Your subscription has expired")
          this.$warningAlert('Your subscription has expired');
        }
      },
    },
    
    
  mounted(){
    this.checkAccess1();
    this.addActiveMonth();
  },
  beforeCreate(){
    this.user_info = JSON.parse(localStorage.getItem('user'))
  }
  
  
  }
  </script>
  
  <style scoped>
  .save{
    width: 120px;
  }
  .validity-period.active{
    border: 1px solid #616cf5;
    transition: .3s;
  
  }
  .validity-period.active .round-price{
    background: #616cf5;
    border:none;
  }
  .month{
    width: 76.6%;
  }
  .price{
    padding-bottom: 50px;
  }
  .price-img{
    width:22px;
    height: 22px;
    margin-right: 5px;
  }
  .card-price{
    padding:20px;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    height:370px;
    transition: .4s;
    cursor:pointer;
    font-size:14px;
  }
  .card-price:hover{
    border: 1px solid #616CF5;
  }
  .card-price.active{
    border: 1px solid #616CF5;
  }
  .app{
    background: #F0F2FF;
    position: relative;
  }
  .app h3{
    color: #616CF5;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .app p{
    color: #8C94A5;
    font-size: 14px;
  }
  .app-foot{
    position: absolute;
    bottom: 20px;
  }
  .app-img{
    width: 56px;
    height: 56px;
  }
  .price-title{
    color: #606877;
    font-size: 20px;
    margin-bottom: 0;
  }
  .price-menu{
    padding: 0;
    margin-bottom:20px;
  }
  .price-list{
    list-style-type: none;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  
  .price-foot h1{
    color: #616CF5;
    font-size: 26px;
    margin-right: 5px;
  }
  .price-foot span{
    color: #B0B0B0;
  }
  .validity-period{
    padding: 10px;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    cursor:pointer;
  }
  .validity-title{
    font-size: 20px;
  }
  .valid-desc{
    color: #B0B0B0;
    margin-bottom: 10px;
    font-size:14px;
  }
  .validity-period input{
    width:12px;
    height: 12px;
  }
  
  .total{
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    padding: 10px 20px;
    width:75.5%;
  }
  .total-price h3{
    font-size: 24px;
  }
  .total-left{
    border-right: 1px solid #D3D3D3;
    margin-right: 50px;
    padding-right: 50px;
  }
  .round-price{
    width: 19px;
    height: 19px;
    background: #FFFFFF;
    border-radius:50%;
    display: flex;
    align-items:center;
    justify-content: center;
    border: 1px solid #D3D3D3;
  }
  
  </style>