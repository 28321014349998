<template>
  <div class="catalog">
    <div class="searchAndButtons">
      <div class="d-flex justify-content-between app-buttons">
        <div class="d-flex align-items-center">
          <button v-if="check()" class="app-buttons-item adding-btns" id="add-product" data-toggle="modal"
            data-target="#add-products"><span>+ Add product</span></button>
          <button v-if="check()" class="app-buttons-item adding-btns" @click="getProducts" data-toggle="modal"
            data-target="#add-service"><span>+ Add service</span></button>
          <button v-if="check()" class="app-buttons-item adding-btns" data-toggle="modal"
            data-target="#add-category"><span>+ Add category </span></button>
            <button v-if="check()" class="app-buttons-item" data-target="#import-excell" data-toggle="modal"><img class="img-btn" src="../../assets/icons/set.svg"><span>Import from Excell </span></button>
        </div>
        <div class="d-flex align-items-center">
          <div class="dropdown">
            <button class="app-buttons-item dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img class="img-btn" src="../../assets/icons/filter.svg"><span>Filter</span>
            </button>

            <div class=" dropdown-menu filter-catalogs animate slideIn" aria-labelledby="dropdownMenuButton">
              <form class="filter-product">
                <label>By price</label>
                <div class="d-flex">
                  <input v-model="price_from" class="drop-input">
                  <div class="d-flex">
                    <label class="mr-2 pl-2">to</label>
                    <input v-model="price_to" class="drop-input">
                  </div>
                </div>

                <label>By quantity</label>
                <div class="d-flex">
                  <input v-model="quantity_from" class="drop-input">
                  <div class="d-flex">
                    <label class="mr-2 pl-2">to</label>
                    <input v-model="quantity_to" class="drop-input">
                  </div>
                </div>

                <label>Auction goods</label>
                <div class="d-flex align-items-center mb-3">
                  <label class="custom-checkbox mr-2"><input type="checkbox"><span class="checkmark"></span></label>
                  <span style="font-size:14px">Show only auction items</span>
                </div>

                <label>By category</label>
                <select v-model="selectedCategory" class="select-category form-control">
                  <option v-for="cat in listCategory" :key="cat._id" :value="cat._id">{{ cat.name }}</option>
                </select>

                <button @click="setFilters" type="button" class="app-buttons-item adding-btns"
                  style="width: 100%;margin-top: 5px;display: flex;justify-content: center;align-items: center;">
                  Filter
                </button>

                <button @click="clearFilters" type="button" class="app-buttons-item adding-btns"
                  style="width: 100%;margin-top: 5px;display: flex;justify-content: center;align-items: center;">
                  Clear filters
                </button>
              </form>
            </div>
          </div>
          <button v-if="check()" class="app-buttons-item" @click="deleteAllOrder"><img
              src="../../assets/icons/trash_empty.svg"><span>Remove</span></button>


 
          <div class="dropdown" style="margin-right: 25px">
            <button class="app-buttons-item dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img src="../../assets/icons/moveto.svg"><span>Move to</span>
            </button>

            <div class=" dropdown-menu filter-catalogs animate slideIn" aria-labelledby="dropdownMenuButton">
              <form class="filter-product">
                <div class="d-flex">
                  <div>
                    <label>Select category</label><br>
                    <div class="sel-block-main">
                      <div class="sel-block-main_modal" :class="{ 'show-category': isCategoryVisible }">
                        <div v-for="(category, index) in navigateDisplayParentCategories" :key="category._id">
                          <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'menu' + index"
                            :class="{ active: selectedCategory === category._id }">
                            <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                              <span class="name-category" @click="selectCategoryMove(category._id, category.name)">{{ category.name }} </span>
                              <span v-if="category.children && category.children.length > 0" class="arrow" :class="{ 'arrow-down': isCategoryOpen[index] }"  @click="toggleCategory(index)">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_89_1499)">
                                  <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                                  </g>
                                  <defs>
                                  <clipPath id="clip0_89_1499">
                                  <rect width="18" height="18" fill="white"/>
                                  </clipPath>
                                  </defs>
                                </svg>
                              </span>
                            </p>
                          </li>
                          <div v-show="isCategoryOpen[index]">
                            <div v-for="(child) in category.children" :key="child._id">
                              <li :key="child._id" class="catalog-list catalog-list-child"
                                :id="child.name" :ref="'menu' + index" :class="{ active: selectedCategory === child._id }">
                                <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                                  <span class="name-category" @click="selectCategoryMove(child._id, child.name)">{{ child.name }} </span>
                                  <span v-if="child.children && child.children.length > 0" class="arrow" :class="{ 'arrow-down': isChildOpen[child._id] }" @click.stop="toggleChild(index, child._id)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_89_1499)">
                                      <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_89_1499">
                                      <rect width="18" height="18" fill="white"/>
                                      </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                </p>
                              </li>
                              <div v-show="isChildOpen[child._id]">
                                <li v-for="(nestedChild) in child.children" :key="nestedChild._id" class="catalog-list catalog-list-child catalog-list-child-nested"
                                  :id="nestedChild.name" :ref="'menu' + index" :class="{ active: selectedCategory === nestedChild._id }">
                                  <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                                    <span class="name-category" @click="selectCategoryMove(nestedChild._id, nestedChild.name)">{{ nestedChild.name }}</span>
                                  </p>
                                </li>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input placeholder="Without category" type="text" class="cashback-input" v-model="selectedCategoryInput" readonly @click="toggleCategoryVisibility">
                    </div>
                  </div>
                </div>

                <br>
                <div class="d-flex justify-content-center">
                  <button class="save" @click="moveCategory(currentData.category)">Move</button>
                </div>
              </form>
              
            </div>
          </div>
          <button v-if="check()" class="app-buttons-item" data-turbolinks="true" data-toggle="modal"
            data-target="#import-client"><img src="../../assets/icons/import.svg"><span>Import</span></button>
        </div>
      </div>
      <div class="main-search d-flex align-items-center">
        <img src="../../assets/icons/search-icon.svg" @click="catalogSearch">
        <input class="main-input" type="text" placeholder="Search" v-model="searchText" @keyup.enter="catalogSearch">
      </div>
    </div>
    <div class="main-content">
      <div class="catalog-wrapper d-flex">

        <div class="catalog-menu" style="width:18%;height: 300px;overflow: auto;">
          <ul class="list-group">
            <div >
              <CategoryItem 
                v-for="(category, index) in navigateDisplayParentCategories" 
                :key="category._id" 
                :category="category" 
                :index="index" 
                :selectedCategory="selectedCategory"
                @setCategory="setCategory" 
                @selectCategory="selectCategory" 
                @deleteCategory="deleteCategory" 
              />
            </div>
          </ul>
        </div>

        <ImportClient />
        <ImportExcell :navigateDisplayParentCategories="navigateDisplayParentCategories" :listCategory="listCategory" :getCategories="reFetchCategories" />
        <AddCategory :navigateDisplayParentCategories="navigateDisplayParentCategories" :listCategory="listCategory" :getCategories="reFetchCategories" />
        <EditCategory :listCategory="listCategory" :select_category="select_category"
          :getCategories="reFetchCategories" />
        <AddProduct :navigateDisplayParentCategories="navigateDisplayParentCategories" :listCategory="listCategory" :getProducts="getProducts" :productCustomFields="customFields"/>
        <EditProduct :navigateDisplayParentCategories="navigateDisplayParentCategories" :listCategory="listCategory" :select_product="select_product" :getProducts="getProducts" :productCustomFields="customFields"/>

        <div class="catalog-content" style="width:82%">
          <div class="d-flex main-content-header">
            <div class="table-head" style="width: 5%;"><label class="custom-checkbox"><input id="parent-check"
                  type="checkbox" @change="selectAllProduct" v-model="selectAll"><span class="checkmark"></span></label>
            </div>
            <div class="table-head" style="width: 36%;">Name</div>
            <div class="table-head" style="width: 24%;">Article</div>
            <div class="table-head table-link pr-3" style="width: 13%;" @click="sortByQunatity">Quantity<img
                class="date-pol" style="margin-left:10px" src="../../assets/icons/polygon.svg"></div>
            <div class="table-head table-link" style="width: 13%;" @click="sortByPrice">Price<img class="total-pol"
                style="margin-left:10px" src="../../assets/icons/polygon.svg"></div>
            <div class="table-head" style="width: 8%;"></div>
            <div class="table-head" style="width: 8%;"></div>
          </div>
          <div class="table-content">
            <div style="height:100%; " class="d-flex align-items-center" v-if="spinner">
              <Spinner />
            </div>
            <div v-else>
              <CatalogItem v-on:checkSelection="checkSelection" v-bind:getProducts="getProducts"
                v-on:selectProduct="selectProduct" v-bind:catalogList="catalogToDisplay"
                v-on:deleteProduct="deleteProduct" />
            </div>

          </div>
          <div class="pagination d-flex justify-content-between align-items-center">
            <!--            <div class="d-flex align-items-center">-->
            <!--              <span>Rows per page</span>-->
            <!--              <select class="form-control pagination-select" v-model='perPage'>-->
            <!--                <option value="8">8</option>-->
            <!--                <option value="16">16</option>-->
            <!--                <option value="32">32</option>-->
            <!--              </select>-->
            <!--            </div>-->
            <!--            <div class="d-flex align-items-center"><span>{{current_page}}</span> <span class="mr-1 ml-1">of</span> <span class="mr-2">{{totalPages}}</span>-->
            <!--              <div v-if='showPrev' @click.stop.prevent='currentPage-=1' class=" pagination-btns"><img class="pagination-img"  src="../../assets/icons/prevArrow.svg"></div>-->
            <!--              <div v-else class="pagination-btns " style="opacity: 0.5;"><img class="pagination-img"  src="../../assets/icons/prevArrow.svg"></div>-->
            <!--              <div class=" pagination-btns" v-if='showNext' @click.stop.prevent='currentPage+=1'>  <img class="pagination-img"  src="../../assets/icons/side-arrow.svg"></div>-->
            <!--              <div v-else class=" pagination-btns"  style="opacity: 0.5;">  <img class="pagination-img"   src="../../assets/icons/side-arrow.svg"></div>-->
            <!--            </div>-->

            <div style="display: flex;height: 50px;width: 100%;justify-content: center;align-items: center;">
              <div v-if="currentPage > 3" @click="currentPage--" style="cursor: pointer;">
                <img src="../../assets/icons/prevArrow.svg">
              </div>
              <div class="paginationItem" v-if="currentPage > 3" @click="currentPage = 1; getProducts()"
                style="cursor: pointer;">1</div>
              <div v-if="currentPage > 3">
                ...
              </div>
              <div v-bind:class="{ activePage: currentPage === page2 }" class="paginationItem"
                v-for="page2 in numberOfPagesArray.filter(num => num < currentPage + 3 && num > currentPage - 3)"
                :key="page2" @click="setPage(page2)">
                {{ page2 }}
              </div>
              <div v-if="currentPage < numberOfPagesArray.length - 2">
                ...
              </div>
              <div class="paginationItem" v-if="currentPage < numberOfPagesArray.length - 2"
                @click="currentPage = numberOfPagesArray.slice(-1)[0]; getProducts()" style="cursor: pointer;">
                {{ numberOfPagesArray.slice(-1)[0] }}</div>
              <div v-if="currentPage < numberOfPagesArray.length - 2" @click="currentPage++" style="cursor: pointer;">
                <img src="../../assets/icons/side-arrow.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  /* eslint-disable */

import Spinner from "../Spinner";
import CategoryItem from './CategoryItem.vue';
import EditProduct from "@/modals/catalog/edit-product/EditProduct";
import CatalogItem from "@/components/catalog/CatalogItem";
import AddCategory from "@/modals/catalog/add-category/AddCategory";

import ImportExcell from "@/modals/catalog/import-excell/ImportExcell";

import AddProduct from "@/modals/catalog/add-product/AddProduct";
import EditCategory from "@/modals/catalog/add-category/EditCategory";
import ImportClient from "@/modals/client/ImportClient";
import Swal from 'sweetalert2';
import $ from 'jquery';
export default {
  name: "Catalog",
  components: {
    CategoryItem,
    CatalogItem,
    AddCategory,
    ImportClient,
    EditCategory,
    AddProduct,
    EditProduct,
    Spinner,
    ImportExcell
  },

  data() {
    return {
      spinner: true,
      listCategory: [{ _id: '', name: 'all' }],
      parentCategories: [{ _id: '', name: 'all' }],
      navigateDisplayParentCategories: [{ _id: '', name: 'all' }],
      catalogList: [],
      deletedProducts: [],
      movedCategories: [],
      searchText: '',
      sorting: true,
      selectedCategory: "all",
      perPage: 8,
      currentPage: 1,
      selectAll: false,
      price_to: '',
      price_from: '',
      quantity_from: '',
      quantity_to: '',
      select_product: '',
      select_category: '',
      customFields: {
        productCustomField1: {name: 'Custom field 1', value: ''},
        productCustomField2: {name:'Custom field 1', value: ''},
        productCustomFields: false,
        productCustomColors:{
          required: false,
          values: [],
        },
      },

      numberOfPagesArray: [],


      currentData: {},
      isCategoryOpen: {},
      isChildOpen: {},
      isCategoryVisible: false,
      selectedCategoryInput:'',
    }
  },
  computed: {
    filteredList: function () {
      return this.catalogList
      // .filter(catalog=>{
      //   if(this.price_to.length>0){
      //     return catalog.price >= this.price_from && catalog.price <= this.price_to
      //   }
      //   else if(this.price_to === ''){
      //     return catalog.price >=this.price_from;
      //   }
      //   else{
      //     return catalog
      //   }
      // })
      // .filter(catalog=>{
      //   if(this.quantity_to.length>0){
      //     return catalog.quantity >= this.quantity_from && catalog.quantity <= this.quantity_to
      //   }
      //   else if(this.quantity_to === ''){
      //     return catalog.quantity>=this.quantity_from;
      //   }
      //   else{
      //     return catalog
      //   }
      // })
      // .filter(catalog => {
      //   return catalog.name.toLowerCase().includes(this.search.toLowerCase())
      // })
      // .filter(product => {
      //   if(this.selectedCategory){
      //     return product.category && product.category._id.includes(this.selectedCategory);
      //   }
      //   return true;
      // })
      // .filter(product=>{
      //   if(this.selectedCategory === ""){
      //     return product.category === null
      //   }
      //   return true
      // })
    },
    // current_page(){
    //   if(this.currentPage> this.totalPages){
    //     return Math.ceil(this.filteredList.length / this.perPage)
    //   }
    //
    //   return this.currentPage
    // },

    catalogToDisplay: function () {
      return this.filteredList;
      // let start = (this.current_page - 1) * this.perPage;
      // let end = this.current_page * this.perPage
      // this.filteredList.map((value, index) =>{
      //   value.index = index
      //   return value
      // })
      // return this.filteredList.slice(start, end)
    },
    // totalPages:function(){
    //   return Math.ceil(this.filteredList.length / this.perPage)
    // },

    // showNext(){
    //   return this.currentPage < this.totalPages;
    // },
    // showPrev(){
    //   return this.currentPage > 1;
    // },

  },
  methods: {
    toggleCategoryVisibility() {
      this.isCategoryVisible = !this.isCategoryVisible;
    },
    closeCategory(event) {
      if (!document.querySelector('.sel-block-main').contains(event.target)) {
        // Если нет, закрываем категорию
        this.isCategoryVisible = false;
      }
    },
    selectCategoryMove(categoryId, categoryName) {
      this.currentData.category = categoryId;
      this.selectedCategoryInput = categoryName;
      this.new_category.name = categoryName;
      this.new_category.parent = categoryId;
    },
    toggleCategory(index) {
      console.log(index);
      this.isCategoryOpen = {...this.isCategoryOpen, [index]: !this.isCategoryOpen[index]};
    },
    toggleChild(index, childId) {
      this.isChildOpen = {...this.isChildOpen, [childId]: !this.isChildOpen[childId]};
    },
    toggleSubcategories(category) {
      // Переключаем флаг для показа/скрытия подкатегорий
      if (category.children) {
        category.showChildren = !category.showChildren;
      }
    },



    fetchSettings(){
      this.axios.get(this.url('getSettings'))
        .then((response) => {
          if(response.data.object.productCustomFields) {
            this.customFields.productCustomField1 = response.data.object.productCustomField1
            this.customFields.productCustomField2 = response.data.object.productCustomField2
            this.customFields.productCustomFields = response.data.object.productCustomFields

          }
          if(response.data.object.productCustomColors.required) {
            this.customFields.productCustomColors = response.data.object.productCustomColors
          }
        }).catch(function (error){
          if (error.response) {
            console.log('setCatalog_settings EERRRor',error.response)
          }
        })
    },
    catalogSearch() {
      this.currentPage = 1;
      this.getProducts();
      this.searchStatus = true;
    },
    clearFilters() {
      this.selectedCategory = "all";
      this.price_from = '';
      this.price_to = '';
      this.quantity_from = '';
      this.quantity_to = '';
      this.currentPage = 1;
      this.getProducts();
    },
    setFilters() {
      this.currentPage = 1;
      this.getProducts();
    },
    setPage(page) {
      this.currentPage = page;
      this.getProducts();
    },
    setCategory(id) {
      console.log("setCategory", id)
      this.selectedCategory = id;
      this.currentPage = 1;
      this.getProducts();
    },

    check(access = "catalog", parametr = "active", parametr2 = "canEdit") {
      return this.checkAccess(access, parametr, parametr2)
    },
    selectAllProduct() {
      this.catalogToDisplay.map(product => product['selected'] = this.selectAll)
    },
    checkSelection() {
      let selected = this.filteredList.filter(employee => {
        return employee.selected
      })
      this.selectAll = selected.length === this.filteredList.length
    },
    deleteAllOrder() {
      this.catalogList.forEach((user) => {
        if (user.selected) {
          this.deletedProducts.push(user._id);
        }
      });
      if (this.deletedProducts.length > 0) {
        Swal.fire({
          showConfirmButton: true,
          html: 'Are you sure to remove these<br>products',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Delete',
          buttonsStyling: false,
          customClass: {
            popup: 'sweet-delete',
            confirmButton: 'confirm-btn',
            cancelButton: 'cancel-btn',
            actions: 'btn-group',
            content: 'content-sweet',
            closeButton: 'close-btn'
          },
          showClass: {
            popup: 'animate__animated animate__slideInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }

        }).then((result) => {
          if (result.isConfirmed) {
            this.axios.delete(this.url('deleteProducts'), {
              data: {
                objects: this.deletedProducts
              }
            }).then(() => {
              this.getProducts()
              this.deletedProducts = []
              this.selectAll = false;
              this.$successAlert('All products have been removed')
            }).catch((error) => {
              if (error.response && error.response.data) {
                this.$warningAlert(error.response.data.msg)
              }
            });
          }
          else {
            this.deletedProducts = []
          }
        })
      }


    },
    sortByQunatity() {
      if (this.catalogList.length === 0) {
        return null;
      }
      this.catalogList.sort((a, b) => this.sorting ? (parseInt(a.quantity) - parseInt(b.quantity)) : (parseInt(b.quantity) - parseInt(a.quantity)));
      this.sorting = !this.sorting;
      $('.date-pol').toggleClass('active')
      $('.total-pol').removeClass('active')

    },
    sortByPrice() {
      if (this.catalogList.length === 0) {
        return null;
      }
      this.catalogList.sort((a, b) => this.sorting ? (parseInt(a.price) - parseInt(b.price)) : (parseInt(b.price) - parseInt(a.price)));
      this.sorting = !this.sorting;
      $('.total-pol').toggleClass('active')
      $('.date-pol').removeClass('active')
    },
    selectCategory(id) {
      this.listCategory.map((item) => {
        if (item._id === id) {
          this.select_category = item
          this.select_category.parent = item.parent ? item.parent._id : ''
        }
      })
    },
    selectProduct(id) {
      this.catalogList.map((product) => {
        if (product._id === id) {
          this.select_product = product;
          console.log("this.select_product", this.select_product)
        }
      })
    },
    deleteProduct(id) {
      Swal.fire({
        showConfirmButton: true,
        html: 'Are you sure to remove this <br>product',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        buttonsStyling: false,
        customClass: {
          popup: 'sweet-delete',
          confirmButton: 'confirm-btn',
          cancelButton: 'cancel-btn',
          actions: 'btn-group',
          content: 'content-sweet',
          closeButton: 'close-btn'
        },
        showClass: {
          popup: 'animate__animated animate__slideInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(this.url('deleteProduct', id))
            .then(() => {
              this.getProducts()
              Swal.fire({
                title: 'Success',
                timer: 800,
                text: 'Product has been removed',
                showConfirmButton: false,
                position: 'top-right',
                customClass: {
                  popup: 'success-popup',
                  content: 'success-content',
                  title: 'success-title',
                  header: 'success-header',
                  image: 'success-img'
                },
                showClass: {
                  popup: 'animate__animated animate__zoomIn'
                },

              }
              )
            }).catch((error) => {
              if (error.response && error.response.data) {
                this.$warningAlert(error.response.data.msg)
              }
            });
        }
      })
    },
    deleteCategory(id) {
      Swal.fire({
        showConfirmButton: true,
        html: 'Are you sure to remove this <br>category',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        buttonsStyling: false,
        customClass: {
          popup: 'sweet-delete',
          confirmButton: 'confirm-btn',
          cancelButton: 'cancel-btn',
          actions: 'btn-group',
          content: 'content-sweet',
          closeButton: 'close-btn'
        },
        showClass: {
          popup: 'animate__animated animate__slideInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(this.url('deleteCategory', id))
            .then(() => {
              this.reFetchCategories()
              const idx = this.listCategory.findIndex(el => el._id === id);
              this.$refs[`menu${idx - 1}`].click()
            })
          Swal.fire({
            title: 'Success',
            timer: 800,
            text: 'Category has been removed',
            showConfirmButton: false,
            position: 'top-right',
            customClass: {
              popup: 'success-popup',
              content: 'success-content',
              title: 'success-title',
              header: 'success-header',
              image: 'success-img'
            },
          }
          )
        }
      })
    },
    getProducts() {
      const options = {
        headers: { "x-client-url": this.currentCompanyCatalog },
        params: {
          "page": this.currentPage,
          "categoryId": this.selectedCategory,
          "min": this.price_from,
          "max": this.price_to,
          "minQuantity": this.quantity_from,
          "maxQuantity": this.quantity_to,
          "searchText": this.searchText,
        },
      }
      this.axios.get(this.url('getProducts'), options)
        .then((response) => {
          this.catalogList = response.data.objects;
          this.numberOfPagesArray = Array.from({ length: response.data.pagesCount || 0 }, (_, i) => i + 1);
          this.spinner = false;
        })
      this.axios.get(this.url('getCategoryPP')) 
      this.axios.get(this.url('getProductPP'))   
      this.axios.get(this.url('getCheckPP'))
    },
    reFetchCategories() {
      this.getCategories();
      this.getNestedCategories();
    },
    getCategories() {
      this.axios.get(this.url('getCategories') + '?type=product')
        .then((res) => {
          this.listCategory = res.data.objects;
          this.listCategory.unshift({ _id: "", name: 'Without category' });
          // eslint-disable-next-line
          // debugger
        })
    },
    getNestedCategories() {
      this.axios.get(this.url('getCategories/nested') + '?type=product')
        .then((res) => {
          console.log(res.data.objects);
          this.parentCategories = res.data.objects;
          this.navigateDisplayParentCategories = [...res.data.objects];
          this.navigateDisplayParentCategories.unshift({ _id: null, name: 'all' })
        })
    },
    moveCategory(id) {
      this.catalogList.forEach((user) => {
        if (user.selected) {
          this.movedCategories.push(user._id)
        }
      });
      if (this.movedCategories.length === 0) {
        this.$warningAlert("Please choose a product")
      }
      else {
        const submitObj = {
          objects: this.movedCategories,
          category: id
        }
        if (id === "") {
          submitObj['category'] = null;
        }

        this.axios.put(this.url('updateProductsCategory'), submitObj)
          .then(() => {
            this.movedCategories = [];
            this.selectAll = false;
            this.getProducts()
            this.$informationAlert("Change are saved")
          }).catch((error) => {
            if (error.response && error.response.data) {
              this.$warningAlert(error.response.data.msg)
            }
          });
      }
    }
  },

  mounted() {
    this.fetchSettings()
    this.getCategories()
    this.getNestedCategories()
    this.getProducts()
  },

}
</script>￼

<style scoped>
.paginationItem {
  width: 28px;
  min-width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.activePage {
  background-color: #616cf5;
  color: #fff;
  font-weight: bold;
}

.select-category {
  height: 35px;
  background-position-y: 50%;
  font-size: 14px;
}

.filter-product label {
  font-size: 15px;
}

.pagination {
  width: calc(82% - 275px);
}

.filter-product {
  padding: 20px;
}

.filter-product label {
  font-weight: normal;
}

.filter-catalogs {
  width: 17rem;
  margin-top: 44px;

}

.catalog {
  margin: 0 30px;
  height: calc(100vh - 90px);
  overflow: hidden;
}


.adding-btns {
  background: #E9ECFF;
  color: #616CF5;
}







.sel-block-main{
  position: relative;
}
.sel-block-main input{
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal{
  position: absolute;
  top: 100%;
  background: white;
  z-index: 1;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 2px 11px 35px 0px rgba(0, 0, 0, 0.1);
  display: none;
  min-width: 120px;
  padding-bottom: 20px;
  max-height: 634px;
  overflow-y: auto;
}
.sel-block-main .sel-block-main_modal.show-category{
  display: block;
}
.sel-block-main .sel-block-main_modal div .catalog-list{
  list-style: none;
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal div .catalog-list:hover{
  background: rgb(248, 249, 255);
  color: black;
}
.sel-block-main .sel-block-main_modal .catalog-list + div {
    margin-left: 16px;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 0 16px;
  position: relative;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text::after{
    content: '';
    position: absolute;
    display: inline-block;
    height: 1px;
    background: rgb(211, 211, 211);
    left: auto;
    bottom: 0;
    width: calc(100% - 32px);
    right: auto;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow, .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow path{
  transition: 0.3s ease;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow:hover path{
  fill: black;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow.arrow-down{
  transform: rotate(180deg);
}
.catalog-list .category-text .name-category{
  width: 100%;
  padding: 15px 0px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>