<template>
  <div>
    <li class="catalog-list" :id="category.name" :ref="'menu' + index"
      :class="{ active: selectedCategory === category._id }" @click="onClickCategory(category._id)">
      <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top" :title="category.name">
        {{ category.name }}
      </p>
      <div class="dropdown dropMenu">
        <div class="dropdown-toggle" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <img v-if="category._id" src="../../assets/icons/three-dots.svg">
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu">
          <ul class="list-group">
            <li class="list-group-item" data-toggle="modal" data-target="#edit-category"
              @click="selectCategoryWrapper(category._id)">Edit</li>
            <li class="list-group-item" @click.stop.prevent="deleteCategoryWrapper(category._id)">Delete</li>
          </ul>
        </div>
      </div>
    </li>
    <div v-if="category.children && category.children.length > 0" style="padding-left: 20px;">
      <CategoryItem 
        v-for="(child, childIndex) in category.children" 
        :key="child._id" 
        :category="child" 
        :index="index + '_' + childIndex"
        :selectedCategory="selectedCategory" 
        @setCategory="emitSetCategory" 
        @selectCategory="emitSelectCategory" 
        @deleteCategory="emitDeleteCategory" 
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryItem',
  props: {
    category: Object,
    index: [String, Number],
    selectedCategory: String
  },
  methods: {
    onClickCategory(id) {
      console.log("onClickCategory", id);
      this.$emit('setCategory', id);
    },
    selectCategoryWrapper(id) {
      this.$emit('selectCategory', id);
    },
    deleteCategoryWrapper(id) {
      this.$emit('deleteCategory', id);
    },
    emitSetCategory(id) {
      this.$emit('setCategory', id);
    },
    emitSelectCategory(id) {
      this.$emit('selectCategory', id);
    },
    emitDeleteCategory(id) {
      this.$emit('deleteCategory', id);
    }
  }
};
</script>

<style scoped>
.catalog-list {
  list-style-type: none;
  border: none;
  height: 35px;
  border-radius: 5px;
  padding: 0 20px;
  padding-right: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.catalog-list-child {
  background-color: #F4F4F4;
  padding-left: 10px;
}

.catalog-list-child-nested {
  background-color: #D1D1D1;
  padding-left: 20px;
}

.active {
  background-color: #E9ECFF;
  color: #616CF5;
}
</style>
