<template>
  <div class="parent-modal">
  <div class="modal myModal fade" id="import-excell" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content category-content">
        <div class="modal-header category-header align-items-center">
          <h3 class="modal-title">Import form excell</h3>
          <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
          </button>
        </div>
        <div class="modal-body category-body">
          <form @submit.prevent="uploadFile" class="modal-form">
            <label>Select .xlsx file</label>
            <input @change="onFileChange($event)" ref="uploadFile" accept=".xlsx" id="fileInput" type="file" name="fileInput">

            <br>
            <br>
            <a :href="exampleFileUrl" download>Download example file</a>
            <br>

            <div class="d-flex justify-content-end">
              <button class="save">Import</button>
            </div>
          </form>

        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>

import $ from 'jquery'
export default {
name: "ImportExcell",
  props:{
    listCategory:{
      type:Array,
      required:true
    },
    getCategories:{
      type:Function,
    },
    navigateDisplayParentCategories:{
      type:Array,
      required:true
    },
  },
  data(){
    return{
      new_category: {
        name: '',
        type: 'product',
        parent: ''
      },

      currentData: {},
      isCategoryOpen: {},
      isChildOpen: {},
      selectedCategory: null,
      isCategoryVisible: false,
      selectedCategoryInput:'',
      file: null,
      exampleFileUrl: '/example_import_products.xlsx',
    }
  },
  methods:{
    onFileChange(event) {
      var valid = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      var file = event.target.files[0];

      console.log("file", file)
      if (file) {
        if (file.size > 10000000) {
          this.$warningAlert('File size exceeds 10 mb');
        } else if (!valid.includes(file.type)) {
          this.$warningAlert('Invalid file type, only .xlsx files are allowed');
        } else {
          this.file = file;
        }
      }
    },
    uploadFile() {
      if (this.file) {
        var formData = new FormData();
        formData.append('file', this.file);

        // Используем Axios для отправки файла на сервер
        this.axios.post(this.url('importExcellProducts'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          this.$successAlert('File uploaded successfully')
          console.log('File uploaded successfully', response);
        })
        .catch(error => {
          this.$warningAlert('Error uploading file')
          console.error('Error uploading file', error);
        });

        $('#import-excell').modal("hide")
      } else {
        this.$warningAlert('Please select a file first');
      }
    },

    onSubmit(){
      const data = new FormData();
      data.append('name', this.new_category.name)
      data.append('type', this.new_category.type)
      data.append('parent', this.new_category.parent)

      this.axios.post(this.url('addCategory'),data)
            .then(()=>{
              this.$successAlert('Category has been added')
              this.getCategories()
            })
            .catch((error)=>{
              this.$warningAlert('Failed to add category')

              console.log("Error"+error)
            })

      $('#import-excell').modal("hide")
      this.new_category.name = ''
      },
  }
}
</script>

<style scoped>
.modal-select{
  font-size: 14px;
}
.category-content{
  border:0;
  border-radius: 0;
  width: 562px;
}
.parent-modal{
  display: flex;
}
.category-body{
  padding: 0 50px;
  padding-bottom: 34px;
}
.category-body input{
  width: 100%;
}
.category-header{
  padding:34px 50px;
}



.sel-block-main{
  position: relative;
}
.sel-block-main input{
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal{
  position: absolute;
  top: 100%;
  background: white;
  z-index: 1;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 2px 11px 35px 0px rgba(0, 0, 0, 0.1);
  display: none;
  min-width: 120px;
  padding-bottom: 20px;
  max-height: 634px;
  overflow-y: auto;
}
.sel-block-main .sel-block-main_modal.show-category{
  display: block;
}
.sel-block-main .sel-block-main_modal div .catalog-list{
  list-style: none;
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal div .catalog-list:hover{
  background: rgb(248, 249, 255);
  color: black;
}
.sel-block-main .sel-block-main_modal .catalog-list + div {
    margin-left: 16px;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 0 16px;
  position: relative;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text::after{
    content: '';
    position: absolute;
    display: inline-block;
    height: 1px;
    background: rgb(211, 211, 211);
    left: auto;
    bottom: 0;
    width: calc(100% - 32px);
    right: auto;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow, .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow path{
  transition: 0.3s ease;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow:hover path{
  fill: black;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow.arrow-down{
  transform: rotate(180deg);
}
.catalog-list .category-text .name-category{
  width: 100%;
  padding: 15px 0px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>