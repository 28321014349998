<template>
  <div class="general">

      <ul class="cd-accordion-menu">
        <IntegrationItem :model="treeData"></IntegrationItem>
      </ul>


    <br><br><br>

    
  </div>
</template>

<script>
import IntegrationItem from "./IntegrationItem.vue";

var treeData = {
  name: "1c exchange",
  children: [
    {
      name: "Sub Group",
      children: [{ name: "Item" }, { name: "Item" }]
    },
    { name: "Item" }
  ]
};

export default {
  name: "General",
  components:{
    IntegrationItem
  },
  data(){
    return{
      treeData: treeData,
      spinner:true,
      oneCApiAddress:"",
      oneCApiLogin:"",
      oneCApiPassword: "",
      tokenPosterPos: "",
      freedompaySecretKey: "",
      freedompayShopId: "",
      id: "",
      settings:{},
    }
  },
  methods:{
    addNewVal(field){
      this[field].values.unshift('')
    },
    removeVal(field, index){
      this[field].values.splice(index, 1)
    },
    updateSettings(){
      let that=this;
      let url = this.url('updatePersonalSettings');
      this.axios.put(url, {
        _id:this.id,
        oneCApiAddress:this.oneCApiAddress,
        oneCApiLogin:this.oneCApiLogin,
        oneCApiPassword:this.oneCApiPassword,
      }).then(function (response) {
        console.log(response);
        that.$successAlert('Updated');
      }).catch(function(error){
        if (error.response) {
          if(error.response.data && !error.response.data.errors){
            that.$warningAlert(error.response.data.msg)
          }else{
            that.$warningAlert('Something went wrong');
          }
          that.$warningAlert(Object.values(error.response.data.errors),"Errors");
        }
      });
    },
    updateSettingsGeneral(){
      let that=this;
      let url = this.url('updateSettings');
      this.settings.tokenPosterPos = that.tokenPosterPos
      this.settings.freedompaySecretKey = that.freedompaySecretKey
      this.settings.freedompayShopId = that.freedompayShopId
      this.axios.put(url, this.settings).then(function (response) {
        console.log(response);
        that.$successAlert('Updated');
      }).catch(function(error){
        if (error.response) {
          if(error.response.data && !error.response.data.errors){
            that.$warningAlert(error.response.data.msg)
          }else{
            that.$warningAlert('Something went wrong');
          }

          that.$warningAlert(Object.values(error.response.data.errors),"Errors");
        }
      });
    },
    getDaysLeft(selectedDate){
      let today = this.$moment();
      let start = this.$moment(selectedDate).startOf('day');
      let end = this.$moment(today).startOf('day');
      return start.diff(end, 'days',true);
    },
    checkAccess1(){
      this.user_info = JSON.parse(localStorage.getItem('user'))
      let days_left = this.getDaysLeft(this.user_info.activeBefore)
      console.log("days_left", days_left)
      if (days_left <= 0) {
        this.$router.push({name: 'Price'})
    }
    }
  },
  mounted(){
    this.checkAccess1();
  },

  beforeCreate(){
    let that = this;
    this.axios
        .get(this.url('getPersonalSettings'))
        .then(function (response){
          that.spinner = false;
          let settings = response.data.user;
          that.oneCApiAddress = settings.oneCApiAddress || '';
          that.oneCApiLogin = settings.oneCApiLogin || '';
          that.oneCApiPassword = settings.oneCApiPassword || '';
          that.id= settings._id || '';
        })

    this.axios
        .get(this.url('getSettings'))
        .then(function (response){
          that.spinner = false;
          let settings = response.data.object;
          that.settings = settings
          that.tokenPosterPos = settings.tokenPosterPos || '';
          that.freedompaySecretKey = settings.freedompaySecretKey || '';
          that.freedompayShopId = settings.freedompayShopId || '';
        })
  },
}

</script>

<style scoped>

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  color: #fff;
  background: #949c4e;
  background: linear-gradient(
    115deg,
    rgba(86, 216, 228, 1) 10%,
    rgba(159, 1, 234, 1) 90%
  );
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

ol, ul {
	list-style: none;
  padding: 0px;
}

a {
  color: #a0f6aa;
  text-decoration: none;
}

h1 {
  text-align: center;
  width: 100%;
  margin: 2em auto 0;
  color: #507b55;
  font-weight: bold;
}

.cd-accordion-menu {
  width: 100%;
  min-width: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.cd-accordion-menu li {
  user-select: none;
}

.cd-accordion-menu li span {
    float: right;
}

.cd-accordion-menu label, .cd-accordion-menu a {
  position: relative;
  display: block;
  padding: 18px 18px 18px 45px;
  box-shadow: inset 0 -1px #000;
  color: #ffffff;
}
.no-touch .cd-accordion-menu label:hover,
.no-touch .cd-accordion-menu a:hover {
  background: #52565d;
}
.cd-accordion-menu label::before,
.cd-accordion-menu label::after,
.cd-accordion-menu a::after {
  /* icons */
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cd-accordion-menu label {
  cursor: pointer;
}

.cd-accordion-menu label span {
  float: right;
  color: #828282;
}

.cd-accordion-menu li.file > label::before {
content: "\f15b";  
}
.cd-accordion-menu li.folder > label::before {
  content: "\f054";
}

.cd-accordion-menu li.file > label::before {
content: "\f15b";  
}
.cd-accordion-menu li.add > label::before {
  content: "\f067";
}


.cd-accordion-menu label::before {
  /* arrow icon */
  font: normal normal normal 14px/1 FontAwesome;
  left: 18px;
  transform: translateY(-50%);
  transition: transform 0.3s;
}
.cd-accordion-menu label.open::before {
  transform: translateY(-25%) rotate(90deg);
}

.cd-accordion-menu ul label,
.cd-accordion-menu ul a {
  background: #000;
  box-shadow: inset 0 -1px #141617;
  padding-left: 82px;
}
.no-touch .cd-accordion-menu ul label:hover,
.no-touch .cd-accordion-menu ul a:hover {
  background: #3c3f45;
}
.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
  box-shadow: none;
}
.cd-accordion-menu ul label::before {
  left: 36px;
}
.cd-accordion-menu ul label::after,
.cd-accordion-menu ul a::after {
  left: 59px;
}
.cd-accordion-menu ul ul label,
.cd-accordion-menu ul ul a {
  padding-left: 100px;
}
.cd-accordion-menu ul ul label::before {
  left: 54px;
}
.cd-accordion-menu ul ul label::after,
.cd-accordion-menu ul ul a::after {
  left: 77px;
}
.cd-accordion-menu ul ul ul label,
.cd-accordion-menu ul ul ul a {
  padding-left: 118px;
}
.cd-accordion-menu ul ul ul label::before {
  left: 72px;
}
.cd-accordion-menu ul ul ul label::after,
.cd-accordion-menu ul ul ul a::after {
  left: 95px;
}


@media only screen and (max-width: 900px) {
  .__mainContainer {
    flex-direction: column;
  }
}

.switch{
  flex-basis:40px;
  flex-grow: 0;
  flex-shrink: 0;
}

.slider.round{
  width: 39px;
}
.cashback-input{
  width: 100%;
}
.save{
  width: 120px;

}

.form input{
  width: 100%;
}


.help-settings .cashback-input{
  width: 100%;
  margin-bottom: 15px;
}

.left-general .cashback-input{
  margin-bottom: 20px;
}
</style>